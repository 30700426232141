import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { getSkuByEAN } from "../../redux/modules/products";
import {
  orderForm,
  addItem,
  updateItem,
  setMessage,
} from "../../redux/modules/cart";
import history from "../../history";
import carrito from "../../assets/carrito.svg";
import quitar from "../../assets/quitar.svg";
import alert from "../../assets/alert.svg";
import check from "../../assets/check.svg";

window.code = "";

function MiniCart(props) {
  const [cartType, setCartType] = React.useState("cart");
  const [modalError, setModalError] = React.useState(false);
  const [messageError,setMessageError] = React.useState("")
  const [modalRemove, setModalRemove] = React.useState(false);
  const [itemToRemove, setItemToRemove] = React.useState(null);

  React.useEffect(() => {
    if (!props.cart.orderForm) props.orderForm();

    // if(!props.cart.orderForm) {
    //     setTimeout(() => {
    //         props.addItem(3544, 1)
    //       },1000)
    // }

    document.addEventListener("keypress", read);

    setInterval(() => {
      if (history.location.pathname.includes("/cart")) setCartType("cart");
      if (history.location.pathname.includes("/checkout"))
        setCartType("checkout");
    }, 100);
  }, []);

  const read = (e) => {
    if (window.location.pathname === "/cart") {
      if (e.keyCode === 13 || localStorage.getItem("ean")) {
        let value = window.code || localStorage.getItem("ean")
        console.log("value es",value)
        addItemByEAN(value);
      } else {
        window.code += e.key;
      }
    }
  };

  const addItemByEAN = (ean) => {
    window.code = "";
    props.getSkuByEAN(ean, function (res) {
      let skuId = res.data.Id;
      if (skuId !== undefined) {
        document.removeEventListener("keypress", read);
        props.addItem(skuId, 1);
      } else {
        console.log("Res data es",res)
        setMessageError(res?.msg)
        setModalError(true);
      }
    });
  };
  const { orderForm, loading, message } = props.cart;

  React.useEffect(() => {
    const selectDiv = document.getElementById("divCar");

    if (orderForm && selectDiv) {
      selectDiv.scrollTop = 2000;
    }
  }, [orderForm]);

  return (
    <>
      {orderForm && orderForm.items && orderForm.items.length ? (
        <div
          className="h-100"
          style={{ borderLeft: "1px solid #E1E1E1", padding: "3.075vw" }}
        >
          {cartType === "cart" ? (
            <h4
              className="tit-3-b text-principal m-0"
              style={{ paddingBottom: "3.075vw" }}
            >
              <img src={carrito} style={{ width: "2.050vw" }} /> Carrito
            </h4>
          ) : null}

          {cartType === "checkout" ? (
            <h4
              className="tit-5-b text-negro m-0"
              style={{ paddingBottom: "3.075vw" }}
            >
              Resumen
            </h4>
          ) : null}

          <div
            id="divCar"
            style={{ overflow: "hidden", overflowY: "auto", height: "22vw" }}
          >
            <ul className="m-0 p-0" style={{ listStyle: "none" }}>
              {orderForm &&
                orderForm.items.map((sku, index) => {
                  return (
                    <li
                      className="d-flex mb-3"
                      style={{ borderTop: "1px solid #c8c8c8" }}
                    >
                      <img
                        src={sku.imageUrl.replace("-55-55", "-100-100")}
                        style={{ width: "7.321vw" }}
                      />
                      <div className="w-100 pl-3">
                        <div className="w-100 d-flex align-items-start">
                          <p className="txt-1-r text-g8 mt-3">{sku.name} </p>
                          <img
                            src={quitar}
                            className="ml-auto p-3 pt-4"
                            style={{ cursor: "pointer", width: "3vw" }}
                            onClick={() => [
                              setItemToRemove(index),
                              setModalRemove(true),
                            ]}
                          />
                        </div>
                        <div className="d-flex align-items-end">
                          {cartType === "cart" ? (
                            <>
                              <div className="d-flex align-items-center">
                                <Button
                                  color="success"
                                  className="border-none p-0 d-flex justify-content-center align-items-center bg-secundario border-secundario"
                                  style={{
                                    borderRadius: "50%",
                                    width: "1.757vw",
                                    height: "1.757vw",
                                    fontSize: "1.1vw",
                                  }}
                                  onClick={() =>
                                    props.updateItem(index, sku.quantity - 1)
                                  }
                                  disabled={sku.quantity === 1}
                                >
                                  -
                                </Button>
                                <p
                                  className="mb-0 text-center"
                                  style={{ width: "2.050vw" }}
                                >
                                  {sku.quantity}
                                </p>
                                <Button
                                  color="success"
                                  className="border-none p-0 d-flex justify-content-center align-items-center bg-secundario border-secundario"
                                  style={{
                                    borderRadius: "50%",
                                    width: "1.757vw",
                                    height: "1.757vw",
                                    fontSize: "1.1vw",
                                  }}
                                  onClick={() =>
                                    props.updateItem(index, sku.quantity + 1)
                                  }
                                >
                                  +
                                </Button>
                              </div>
                              <div className="text-right ml-auto mr-3">
                                {sku.listPrice !== sku.sellingPrice ? (
                                  <p className="m-0 precio-antes-2 text-g6">
                                    <strike>
                                      ${(sku.listPrice / 100).toFixed(2)}
                                    </strike>
                                  </p>
                                ) : null}
                                <h5 className="m-0 precio-precio-2 text-g9">
                                  <b>${(sku.sellingPrice / 100).toFixed(2)}</b>
                                </h5>
                              </div>
                            </>
                          ) : null}

                          {cartType === "checkout" ? (
                            <h5 className="m-0 t2-r text-g8 d-flex w-100">
                              <b>{sku.quantity}un.</b>
                              <b className="text-right ml-auto">
                                ${(sku.sellingPrice / 100).toFixed(2)}
                              </b>
                            </h5>
                          ) : null}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div
            className="mt-auto pt-4 pb-4 bg-blanco"
            style={{ position: "fixed", width: "25vw", bottom: "5vh" }}
          >
            <div className="pt-3" style={{ borderBottom: "1px solid #c8c8c8" }}>
              {orderForm &&
                orderForm.totalizers.map((item, key) => {
                  return item.id !== "Shipping" ? (
                    <>
                      <div key={key} className="d-flex pb-3">
                        <p className="m-0 h5-r text-g7">{item.name}</p>
                        <p className="m-0 ml-auto h5-r text-g7">
                          {item.value < 0
                            ? `-$${Math.abs(item.value / 100).toFixed(2)}`
                            : `$${(item.value / 100).toFixed(2)}`}
                        </p>
                      </div>
                    </>
                  ) : null;
                })}
            </div>
            <div className="pt-3 pb-3">
              <div className="d-flex">
                <p className="m-0 h5-b text-negro">
                  <b>Total</b>
                </p>
                <p className="m-0 ml-auto h5-b text-negro">
                  <b>
                    ${orderForm ? (orderForm.value / 100).toFixed(2) : null}
                  </b>
                </p>
              </div>
            </div>
            {cartType === "cart" ? (
              <Button
                color="warning"
                className="bg-principal border-principal btn-2 text-blanco"
                block
                onClick={() => history.push("/checkout/email")}
              >
                Confirmar Compra
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}

      <Modal
        isOpen={modalError}
        toggle={() => setModalError(false)}
        className="alert-error"
      >
        <ModalBody>
          <h2 className="text-danger d-flex align-items-center tit-3-r text-alerta">
            <img src={alert} className="mr-2" style={{ width: "1.816vw" }} />
            Error de escaneo
          </h2>
          <p className="txt-1-r mb-5">
            {messageError}
          </p>
          <Row>
            <Col>
              <Button
                outline
                color="warning"
                className="text-principal"
                block
                style={{ border: "none" }}
                onClick={() => setModalError(false)}
              >
                CANCELAR
              </Button>
            </Col>
            <Col>
              <Button
                color="warning"
                className="bg-principal text-blanco"
                block
                onClick={() => setModalError(false)}
              >
                Volver a escanear
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalRemove}
        toggle={() => setModalError(false)}
        className={`${itemToRemove === null ? "alert-success" : ""}`}
      >
        <ModalBody>
          {itemToRemove !== null ? (
            <>
              <h2 className="tit-3-r text-g9">Eliminar producto</h2>
              <p className="txt-1-r text-g8 mb-5">
                ¿Estás seguro de eliminar este producto de tu compra?
              </p>
              <Row>
                <Col>
                  <Button
                    outline
                    color="warning"
                    className="text-principal"
                    block
                    style={{ border: "none" }}
                    onClick={() => setModalRemove(false)}
                  >
                    CANCELAR
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="warning"
                    className="bg-principal text-blanco"
                    block
                    onClick={() => [
                      props.updateItem(itemToRemove, 0, () => {
                        setItemToRemove(null);
                        setTimeout(() => {
                          setModalRemove(false);
                        }, 2000);
                      }),
                    ]}
                  >
                    Eliminar
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={check} className="mb-4" style={{ width: "3.514vw" }} />
              <h2 className="tit-3-b text-g9">Producto eliminado</h2>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={message !== ""} toggle={() => props.setMessage("")}>
        <ModalBody>
          <>
            <h2 className="tit-3-r text-g9">Mensaje</h2>
            <p className="txt-1-r text-g8 mb-5">{message}</p>
            <Row>
              <Col>
                <Button
                  color="warning"
                  className="bg-principal text-blanco"
                  block
                  style={{ border: "none" }}
                  onClick={() => props.setMessage("")}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </>
        </ModalBody>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  const { cart } = state;
  return {
    cart,
  };
}

function mapDistpachToProps(dispatch) {
  return bindActionCreators(
    {
      orderForm,
      getSkuByEAN,
      addItem,
      updateItem,
      setMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDistpachToProps)(MiniCart);
